<template>
  <div class="_bg-default _80w _100vh pa-3">
    <div class="_100top _60-w pb-3">
      <!-- IF SURVEY CREATED -->
      <v-card
        class="radius-card px-5 pt-5"
        v-if="isSurveyCreated != null"
        elevation="0"
      >
        <v-text-field
          label="Judul survey"
          v-model="isSurveyCreated.judul"
          class="heding"
        ></v-text-field>
        <v-text-field
          label="Deskripsi survey"
          dense
          v-model="isSurveyCreated.keterangan"
        ></v-text-field>
        <v-row>
          <v-col cols="12" md="6" v-if="fields">
            category
            <v-select
              solo
              dense
              hide-details="auto"
              item-text="nama"
              item-value="nama"
              :items="fields.data"
              label="Psikologi klinis"
              v-model="isSurveyCreated.kategori"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6" v-if="fields">
            waktu berakhir
            <v-menu
              v-model="calendar2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="isSurveyCreated.close"
                  label="Waktu Berakhir"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  dense
                  solo
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="isSurveyCreated.close"
                @input="calendar2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            Privasi
            <v-select
              solo
              dense
              hide-details="auto"
              item-text="state"
              item-value="abbr"
              :items="privasi"
              label="privasi"
              v-model="visible"
            ></v-select>
          </v-col>
        </v-row>
        <v-card-actions class="mt-3">
          <v-spacer></v-spacer>
          <v-btn color="green" outlined rounded @click="updateSurvey"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
      <!-- CREATE SOAL -->
      <section class="mt-3" v-if="isSurveyCreated">
        <v-card class="radius-card px-5 pt-5" elevation="0">
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                label="pertanyaan"
                v-model="quest"
                :rules="rules"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                solo
                :items="item"
                item-text="state"
                item-value="abbr"
                v-model="type"
                label="position"
                hide-details
                dense
              ></v-select>
            </v-col>
          </v-row>
          <div v-if="type == 'single'">
            <form @submit.prevent="addElmnt">
              <section class="mt-5 d-flex" v-for="q in elmnt" :key="q">
                <v-radio class="mr-3" disabled></v-radio>
                <v-text-field
                  :label="`Opsi ${q}`"
                  v-model="option[q - 1]"
                  dense
                  hide-details
                  :id="`opt-${q}`"
                ></v-text-field>
              </section>
              <section class="d-flex mt-3">
                <v-btn text class="pl-0" type="addElmnt">
                  <v-radio class="mr-3" disabled></v-radio>
                  Tambahkan opsi</v-btn
                >
              </section>
            </form>
          </div>
          <div v-if="type == 'multiple'">
            <form @submit.prevent="addElmnt">
              <section class="mt-5 d-flex" v-for="q in elmnt" :key="q">
                <v-checkbox value disabled></v-checkbox>
                <v-text-field
                  :label="`Opsi ${q}`"
                  v-model="option[q - 1]"
                  hide-details
                  :id="`opt-${q}`"
                ></v-text-field>
              </section>
              <section class="d-flex mt-3">
                <v-btn text class="pl-0" type="addElmnt">
                  <v-checkbox value disabled></v-checkbox>
                  Tambahkan opsi</v-btn
                >
              </section>
            </form>
          </div>
          <v-card-actions class="mt-3">
            <v-spacer></v-spacer>
            <v-btn color="green" outlined rounded @click="validateQuestion"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-card>
      </section>
      <!-- LIST QUESTION -->
      <section class="mt-3" v-if="isSurveyCreated">
        <div
          class="mb-3"
          v-for="(item, idx) in isSurveyCreated.relationships"
          :key="`xX${idx}Xx`"
        >
          <div v-if="item.soal">
            <v-card
              class="radius-card px-5 pt-5 mb-5"
              v-for="question in item.soal.data"
              :key="`quest-${question.id}`"
              elevation="0"
            >
              <v-row>
                <v-col cols="12" md="11">
                  <h3>{{ question.soal }}</h3>
                </v-col>
                <v-col cols="12" md="1">
                  <div class="d-flex justify-end">
                    <v-btn color="blue" icon @click="editQuestion(question)"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                    <v-btn color="red" icon @click="deleteQuestion(question)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </div>
                </v-col>
              </v-row>
              <div class="py-3">
                <section class="d-flex" v-if="question.tipe == 'single'">
                  <v-radio-group>
                    <v-radio
                      v-for="(val, key) in question.pilihan"
                      :key="key"
                      :label="val"
                      :value="key"
                    ></v-radio>
                  </v-radio-group>
                </section>
                <section class="my-3" v-if="question.tipe == 'multiple'">
                  <v-checkbox
                    v-for="(val, key) in question.pilihan"
                    :key="`multi${key}`"
                    v-model="selected"
                    :label="val"
                    hide-details
                    :value="val"
                    class="ma-1"
                  ></v-checkbox>
                </section>
              </div>
            </v-card>
          </div>
          <div v-if="item.soal" class="d-flex justify-end">
            <div v-if="item.soal.data.length">
              <v-btn color="green" dark @click="resetSurvey"
                >Simpan survey</v-btn
              >
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="pa-2">
      <EditQuestion
        v-bind:dialogQuest="dialogQuest"
        v-bind:dataQuestion="dataQuestion"
        v-bind:optQuestion="optQuestion"
        @close="closeDialog"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import EditQuestion from "../../components/Psycholog/Modal/editQuestion.vue";

export default {
  components: { EditQuestion },
  name: "updateSurvey",
  data() {
    return {
      item: [
        { state: "Single Choice", abbr: "single" },
        { state: "Multiple Choice", abbr: "multiple" }
      ],
      privasi: [
        { state: "Publik", abbr: "public" },
        { state: "Psikolog Saja", abbr: "pampsi" }
      ],
      visible: "public",
      rules: [],
      type: "single",
      title: "Survey Tanpa Judul",
      descriptions: "",
      selected: [],
      quest: "",
      option: [],
      elmnt: 1,
      field: "",
      calendar1: false,
      calendar2: false,
      end_evt: null,
      dataQuestion: null,
      dialogQuest: false,
      optQuestion: []
    };
  },
  computed: {
    ...mapState({
      fields: state => state.fields.fields,
      isSurveyCreated: state => state.survey.survey_byid
    })
  },
  mounted() {
    this.getFields();
    this.getSurveyById();
  },

  // eslint-disable-next-line no-unused-vars
  beforeRouteLeave(to, from, next) {
    let idSurvey = localStorage.getItem("id_survey");
    if (idSurvey) {
      Swal.fire({
        //validation if user want to navigate route
        title: "Survey belum tersimpan!",

        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya"
      }).then(result => {
        if (result.isConfirmed) {
          next();
        }
      });
    } else {
      next();
    }
  },

  methods: {
    editQuestion(item) {
      this.dataQuestion = item;
      Object.keys(item.pilihan).forEach(key => {
        this.optQuestion.push({ key: item.pilihan[key] });
      });
      this.dialogQuest = true;
    },
    closeDialog() {
      this.dialogQuest = false;
      this.optQuestion = [];
      this.getSurveyById();
    },
    getSurveyById() {
      let id = this.$route.params.id;
      this.$store.dispatch("survey/getSurvey", id);
    },
    getFields() {
      this.$store.dispatch("fields/listFields");
    },
    addElmnt() {
      if (this.option.length) {
        this.elmnt = this.elmnt + 1;
        setTimeout(() => this.elementFocused(this.elmnt), 100);
      } else if (this.option.length - 1 == "") {
        console.log("rrrgh");
      } else {
        console.log("mabok");
      }
    },
    elementFocused(count) {
      document.getElementById(`opt-${count}`).focus();
    },
    updateSurvey() {
      let data = new FormData();
      data.append("judul", this.isSurveyCreated.judul);
      data.append("keterangan", this.isSurveyCreated.deskripsi);
      data.append("kategori", this.isSurveyCreated.kategori);
      data.append("close", this.isSurveyCreated.close);
      data.append("visible", this.visible);
      data.append("status_survey", 1);
      let params = {
        id: this.$route.params.id,
        data: data
      };
      this.$store.dispatch("survey/updateSurvey", params).then(data => {
        if (data.status == "success") {
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        } else {
          Swal.fire({
            icon: "error",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        }
      });
    },
    validateQuestion() {
      let exist = this.option.filter(el => {
        return el == null;
      });
      console.log(exist);
      if (exist.length || !this.option.length) {
        Swal.fire({
          icon: "error",
          title: "Opsi tidak boleh kosong!",
          showConfirmButton: false,
          position: "top",
          toast: true,
          timer: 3000
        });
      } else if (this.quest == "") {
        Swal.fire({
          icon: "error",
          title: "Soal tidak boleh kosong!",
          showConfirmButton: false,
          position: "top",
          toast: true,
          timer: 3000
        });
      } else {
        this.createQuest();
      }
    },
    createQuest() {
      let filteredOpt = this.option;
      filteredOpt = filteredOpt.filter(function(el) {
        return el != null;
      });

      let data = {
        survey_id: this.$route.params.id,
        soal: this.quest,
        pilihan: filteredOpt,
        tipe: this.type
      };

      this.$store.dispatch("survey/createQuestion", data).then(data => {
        this.quest = "";
        this.option = [];
        this.elmnt = 1;

        Swal.fire({
          icon: "success",
          title: data.message,
          showConfirmButton: false,
          position: "top",
          toast: true,
          timer: 3000
        });

        this.getSurveyById();
      });
    },
    deleteQuestion(item) {
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("survey/deleteQuestion", item.id).then(data => {
            this.getSurveyById();
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000
            });
          });
        }
      });
    },

    resetSurvey() {
      localStorage.removeItem("id_survey");
      this.title = "Survey Tanpa Judul";
      this.descriptions = "";
      this.$router.push("/psikolog/my-survey");
      this.getSurveyById();
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 70%;
}
.heding {
  font-size: x-large;
}
@media (max-width: 576px) {
  ._60-w {
    width: 100%;
  }
}
@media (max-width: 768px) {
  ._60-w {
    width: 100%;
  }
}
</style>
